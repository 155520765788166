<template>
	<template v-if="$route.name == 'AdminInspect'">
		<h1>Inspect</h1>
		<form id="form" @submit.prevent="submitForm">
			<FormText v-model="formValue" placeholder="entity id" :mR="10" />
			<FormButton :level="1" value="Inspect" :wait="waiting" />
		</form>
		<p v-if="error" class="error-msg" v-html="error"></p>
		<!-- <pre>{{ sampleIds }}</pre> -->
		<div id="sample-ids">
			<div>
				<h3>Sample Artists</h3>
				<template v-if="sampleIds.artists">
					<router-link
						v-for="(artist, i) in sampleIds.artists"
						:key="i"
						:to="{
							name: 'AdminInspect',
							params: { id_username_namePath_wildcard: artist.id },
						}"
						>{{ artist.name }}</router-link
					>
				</template>
				<template v-else><BaseLoadingText /></template>
			</div>
			<div>
				<h3>Sample Artworks</h3>
				<template v-if="sampleIds.artworks">
					<router-link
						v-for="(artwork, i) in sampleIds.artworks"
						:key="i"
						:to="{
							name: 'AdminInspect',
							params: { id_username_namePath_wildcard: artwork.id },
						}"
						>{{ trunc(artwork.artistName + ': ' + artwork.title, 30) }}</router-link
					>
				</template>
				<template v-else><BaseLoadingText /></template>
			</div>
			<div>
				<h3>Sample Users</h3>
				<template v-if="sampleIds.users">
					<router-link
						v-for="(user, i) in sampleIds.users"
						:key="i"
						:to="{
							name: 'AdminInspect',
							params: { id_username_namePath_wildcard: user.id },
						}"
						>{{ user.name }}</router-link
					>
				</template>
				<template v-else><BaseLoadingText /></template>
			</div>
			<div>
				<h3>Sample Lists</h3>
				<template v-if="sampleIds.lists">
					<router-link
						v-for="(list, i) in sampleIds.lists"
						:key="i"
						:to="{
							name: 'AdminInspect',
							params: { id_username_namePath_wildcard: list.id },
						}"
						>{{ list.name }}</router-link
					>
				</template>
				<template v-else><BaseLoadingText /></template>
			</div>
			<div>
				<h3>Sample Rooms</h3>
				<template v-if="sampleIds.rooms">
					<router-link
						v-for="(room, i) in sampleIds.rooms"
						:key="i"
						:to="{
							name: 'AdminInspect',
							params: { id_username_namePath_wildcard: room.id },
						}"
						>{{ room.name }}</router-link
					>
				</template>
				<template v-else><BaseLoadingText /></template>
			</div>
		</div>
	</template>

	<router-view v-else />
</template>

<script>
// Stores
import { useApiStore } from '@/stores/ApiStore'

// Components
import FormText from '@/components/FormText'
import FormButton from '@/components/FormButton'
import BaseLoadingText from '@/components/BaseLoadingText'

// Internal
import { trunc } from '@/use/StringMagic'
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'AdminInspect',
	components: {
		FormText,
		FormButton,
		BaseLoadingText,
	},
	props: {
		id_username_namePath_wildcard: String,
	},
	setup() {
		injectMetaData({
			title: 'Admin: Inspect',
		})

		const apiStore = useApiStore()
		const adminInspectApi = apiStore.loadApi('adminInspect')

		return { adminInspectApi, trunc }
	},
	data() {
		return {
			formValue: '',
			error: '',
			waiting: false,
			sampleIds: {},
		}
	},
	watch: {
		formValue() {
			this.error = ''
		},
	},

	// Whenever route changes
	async beforeRouteUpdate(to, from, next) {
		if (to.name == from.name && to.name == 'AdminInspect') {
			this.formValue = to.params.id_username_namePath_wildcard
			this.inspectEntity()
			next()
		} else {
			next()
		}
	},

	// If forward didn't work, show error
	async mounted() {
		// Handle error if forward failed
		if (this.id_username_namePath_wildcard) {
			this.formValue = this.id_username_namePath_wildcard
			this.handleError()
		}

		// List id sample
		this.sampleIds = (await this.adminInspectApi.getSampleIds()).data
	},
	methods: {
		// Submit form
		async submitForm() {
			if (this.id_username_namePath_wildcard == this.formValue) {
				// Route doesn't change
				// This happens when inspect entity and then hit back
				this.inspectEntity()
			} else {
				// New route
				// Handled by beforeRouteUpdate()
				this.$router.push({
					name: 'AdminInspect',
					params: { id_username_namePath_wildcard: this.formValue },
				})
			}
		},
		async inspectEntity() {
			this.waiting = true
			this.error = ''
			const response = await this.adminInspectApi.getEntityType(this.formValue)
			this.waiting = false

			// Entity doesn't exist
			if (response.status == 404) {
				return this.handleError()
			}

			// Forward to entity
			const entityType = response.data
			this.$router.push({
				name: 'AdminInspectEntity',
				params: { entityType, id_username_namePath: this.formValue },
			})
		},

		handleError() {
			this.error = `Entity <em>'${this.formValue}'</em> not found.`
		},
	},
}
</script>

<style lang="scss" scoped>
#form {
	display: flex;
}

#sample-ids {
	display: flex;
	margin-top: 0.4rem;
}
#sample-ids > div {
	margin-right: 0.4rem;
}
#sample-ids a {
	display: block;
}
</style>
